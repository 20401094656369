<template>
  <component :is="planData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="planData === undefined">
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list'}">
          Plan List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs v-if="planData" pills>

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="PlanIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Edit Plan</span>
        </template>
        <plan-edit-tab-account :user-data="planData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Information -->
      <!-- <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <plan-edit-tab-information class="mt-2 pt-75" />
      </b-tab> -->

      <!-- Tab: Social -->
      <!-- <b-tab>
        <template #title>
          <feather-icon icon="Share2Icon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Social</span>
        </template>
        <plan-edit-tab-social class="mt-2 pt-75" />
      </b-tab>
     -->
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import userStoreModule from '../userStoreModule'
import PlanEditTabAccount from './PlanEditTabAccount.vue'
import PlanEditTabInformation from './PlanEditTabInformation.vue'
import PlanEditTabSocial from './PlanEditTabSocial.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    PlanEditTabAccount,
    PlanEditTabInformation,
    PlanEditTabSocial,
  },
  data() {
    return {
      planData: [],
    }
  },
  mounted() {
    var url = this.$API_URL_USER;
  },
}
</script>

<style>

</style>
