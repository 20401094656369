<template>
  <div>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Name -->
        <b-col cols="12" md="4">
          <b-form-group label="Name" label-for="name">
            <b-form-input id="name" v-model="planData.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Slug -->
        <b-col cols="12" md="4">
          <b-form-group label="Slug" label-for="slug">
            <b-form-input id="slug" v-model="planData.slug" />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group label="Price" label-for="price">
            <b-form-input id="price" v-model="planData.price" type="number" />
          </b-form-group>
        </b-col>

        <!-- Field: Type -->
        <b-col cols="12" md="4">
          <b-form-group label="Type" label-for="type">
            <v-select v-model="planData.type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="typeOptions"
              :reduce="type => type.name" :getOptionLabel="type => type.name" :clearable="false" input-id="type" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group label="Applicable On" label-for="type">
            <v-select v-model="planData.applicable_on" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="applicableOptions" :reduce="applicable => applicable.name"
              :getOptionLabel="applicable => applicable.name" :clearable="false" input-id="type" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" id="check-button-active">
          <b-form-group label-for="active">
            <b-form-checkbox v-model="planData.active" name="check-button" switch>
              Active
            </b-form-checkbox>
          </b-form-group>

        </b-col>


      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="updatePlan()">
      Save Changes
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue';

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  data() {
    return {
      planData: [],
      typeOptions: [
        { name: 'Plan', value: 'plan' },
        { name: 'Add On', value: 'add_on' }
      ],
      applicableOptions: [
        { name: 'All', value: 'all' },
      ]
    }
  },
  mounted() {
    this.fetchPlanByID()
  },
  methods: {
    async fetchPlanByID() {
      const res = await this.$axios.get(`${this.$API_URL_PLANS}/fetchPlanByID/${router.currentRoute.params.id}`);
      return this.planData = res.data.response;
    },

    async updatePlan() {
      let updatePlan = await this.$axios.post(`${this.$API_URL_PLANS}/updatePlan`, {
        data: this.planData
      })

      if (updatePlan.data.response) {
        Vue.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Success`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `Plan Update Successfully`,
          },
        });
      } else {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    }
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#check-button-active {
  top: 27px;
}
</style>
